var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-20 bg-white"},[_c('div',{staticClass:"relative"},[(_vm.disabled)?_c('div',{staticClass:"absolute w-full h-full left-0 top-0 z-10"}):_vm._e(),_c('a-collapse',{staticStyle:{"background-color":"transparent"},attrs:{"activeKey":_vm.activeKey,"bordered":false}},[_c('a-collapse-panel',{key:"3",staticStyle:{"border":"none"},attrs:{"showArrow":false,"disabled":true}},[_c('Header',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-10"},[_vm._v("所获荣誉")]),_c('CompanyStatus',{attrs:{"AuditStatus":_vm.origin.AuditStatus}})],1)]),(_vm.isAddHonor)?_c('Extra',{attrs:{"slot":"extra"},nativeOn:{"click":function($event){return _vm.addHonor.apply(null, arguments)}},slot:"extra"}):_vm._e(),_c('a-row',_vm._l((_vm.honors),function(item,index){return _c('a-col',{key:item.ID,attrs:{"span":24}},[_c('Honors',{attrs:{"index":index + 1,"mode":item.mode,"dataSource":item},scopedSlots:_vm._u([{key:"update",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.cancelHonor(item, index)}}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveHonor(validate, index)}}},[_vm._v("保存")])],1)}}],null,true)},[_c('a-space',{attrs:{"slot":"display"},slot:"display"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("修改")]),_c('a-icon',{attrs:{"type":"form"},on:{"click":function($event){return _vm.updateState(item, 'update')}}})],2),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("删除")]),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.delHonor(index)}}})],2)],1)],1)],1)}),1)],1),_c('a-collapse-panel',{key:"4",staticStyle:{"border":"none"},attrs:{"showArrow":false,"disabled":true}},[_c('Header',{attrs:{"slot":"header"},slot:"header"},[_vm._v("所获专利")]),(_vm.isAddPatent)?_c('Extra',{attrs:{"slot":"extra"},nativeOn:{"click":function($event){return _vm.addPatent.apply(null, arguments)}},slot:"extra"}):_vm._e(),_c('a-row',_vm._l((_vm.patents),function(item,index){return _c('a-col',{key:item.ID,attrs:{"span":24}},[_c('Patents',{attrs:{"index":index + 1,"mode":item.mode,"dataSource":item},scopedSlots:_vm._u([{key:"update",fn:function(ref){
var validate = ref.validate;
return _c('a-space',{},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){return _vm.cancelPatent(item, index)}}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.savePatent(validate, index)}}},[_vm._v("保存")])],1)}}],null,true)},[_c('a-space',{attrs:{"slot":"display"},slot:"display"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("修改")]),_c('a-icon',{attrs:{"type":"form"},on:{"click":function($event){return _vm.updateState(item, 'update')}}})],2),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("删除")]),_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.delPatent(index)}}})],2)],1)],1)],1)}),1)],1)],1)],1),_c('div',{staticClass:"text-right px-20"},[(
        !_vm.isManager &&
        _vm.origin.AuditStatus === 0 &&
        _vm.honors.length &&
        _vm.patents.length
      )?_c('a-button',{attrs:{"disabled":_vm.origin.AuditStatus !== 0,"type":"primary"},on:{"click":_vm.submitInfo}},[_vm._v("提交")]):_vm._e(),(_vm.isManager && _vm.origin.AuditStatus === 1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.modal = true}}},[_vm._v("审核")]):_vm._e()],1),_c('a-modal',{attrs:{"title":"审核","okText":"审核","cancelText":"取消"},on:{"ok":_vm.evaluateRecoginze},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('a-form-model',{attrs:{"model":_vm.evaluate}},[_c('a-form-model-item',[_c('a-select',{model:{value:(_vm.evaluate.AuditStatus),callback:function ($$v) {_vm.$set(_vm.evaluate, "AuditStatus", $$v)},expression:"evaluate.AuditStatus"}},[_c('a-select-option',{attrs:{"value":2}},[_vm._v("审核通过")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v("审核失败")])],1)],1),(_vm.evaluate.AuditStatus === 3)?_c('a-form-model-item',[_c('gf-textarea',{attrs:{"required":true,"prop":"Feedback","max":300,"rows":5},model:{value:(_vm.evaluate.Feedback),callback:function ($$v) {_vm.$set(_vm.evaluate, "Feedback", $$v)},expression:"evaluate.Feedback"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hover:bg-f9 px-20 py-10",class:{ 'bg-f9': _vm.mode === 'update' }},[(_vm.mode === 'display')?_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-20 border border-eee",staticStyle:{"width":"214px","height":"160px"}},[(_vm.logo)?_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":_vm.logo,"alt":""}}):_vm._e()]),_c('div',{staticStyle:{"width":"calc(100% - 234px)"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-grow font-bold"},[_c('span',[_vm._v(_vm._s(_vm.model.Name))]),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('span',[_vm._v(_vm._s(_vm.model.Address))])]),_c('div',{staticClass:"flex-shrink-0"},[_vm._t("display")],2)]),_c('div',{staticClass:"mt-10 text-sm"},[_c('div',{staticClass:"text-999 mb-10"},[_vm._v("基地详情：")]),_c('div',[_vm._v(" "+_vm._s(_vm.model.Product)+" ")])])])])]):(_vm.mode === 'update')?_c('div',[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelCol":{ span: 8 },"wrapperCol":{ span: 15 },"labelAlign":"left","colon":false}},[_c('gf-form-item',{attrs:{"prop":"Name","label":"基地名称","rules":[
          {
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          },
          {
            max: 15,
            message: '超出字数限制',
            trigger: ['change', 'blur'],
          } ]}},[_c('gf-re-input',{attrs:{"placeholder":"15字以内"},model:{value:(_vm.model.Name),callback:function ($$v) {_vm.$set(_vm.model, "Name", $$v)},expression:"model.Name"}})],1),_c('gf-form-item',{attrs:{"prop":"Address","label":"基地所在地","rules":[
          {
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          },
          {
            max: 15,
            message: '超出字数限制',
            trigger: ['change', 'blur'],
          } ]}},[_c('gf-re-input',{attrs:{"placeholder":"15字以内"},model:{value:(_vm.model.Address),callback:function ($$v) {_vm.$set(_vm.model, "Address", $$v)},expression:"model.Address"}})],1),_c('gf-form-item',{attrs:{"prop":"Product","label":"基地主要产品","rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('gf-re-textarea',{attrs:{"placeholder":"请输入基地主要产品","rows":5,"max":300},model:{value:(_vm.model.Product),callback:function ($$v) {_vm.$set(_vm.model, "Product", $$v)},expression:"model.Product"}})],1),_c('gf-form-item',{attrs:{"prop":"Photos","label":"基地图片","subLabel":"支持JPG、PNG、JPEG等格式文件需小于5M，建议尺寸800*600","wrapperCol":{ span: 7 },"rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('picture-upload',{attrs:{"scale":{ w: 330, h: 248 },"width":330,"height":248,"accept":".png,.jpg,.jpeg","size":5,"field":"6"},model:{value:(_vm.model.Photos),callback:function ($$v) {_vm.$set(_vm.model, "Photos", $$v)},expression:"model.Photos"}})],1)],1),_c('div',{staticClass:"text-right"},[_vm._t("update",null,null,{ validate: _vm.validate })],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="py-20 bg-white">
    <div class="relative">
      <div
        class="absolute w-full h-full left-0 top-0 z-10"
        v-if="disabled"
      ></div>
      <a-collapse
        :activeKey="activeKey"
        :bordered="false"
        style="background-color: transparent"
      >
        <!-- <a-collapse-panel
          style="border: none"
          key="1"
          :showArrow="false"
          :disabled="true"
        >
          <Header slot="header">
            <div class="flex items-center">
              <span class="mr-10">主营产品</span>
              <CompanyStatus :AuditStatus="origin.AuditStatus" />
            </div>
          </Header>
          <Extra @click.native="addProduct" v-if="isAddProduct" slot="extra" />
          <a-row>
            <a-col :span="24" v-for="(item, index) in products" :key="item.ID">
              <MainProduct :mode="item.mode" :dataSource="item">
                <a-space slot="update" slot-scope="{ validate }">
                  <a-button
                    @click="cancelProduct(item, index)"
                    type="primary"
                    ghost
                    >取消</a-button
                  >
                  <a-button @click="saveProduct(validate, index)" type="primary"
                    >保存</a-button
                  >
                </a-space>
                <a-space slot="display">
                  <a-tooltip>
                    <template slot="title">修改</template>
                    <a-icon type="form" @click="updateState(item, 'update')" />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">删除</template>
                    <a-icon type="delete" @click="delProduct(index)" />
                  </a-tooltip>
                </a-space>
              </MainProduct>
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel
          style="border: none"
          key="2"
          :showArrow="false"
          :disabled="true"
        >
          <Header slot="header">生产基地</Header>
          <Extra @click.native="addBase" v-if="isAddBase" slot="extra" />
          <a-row>
            <a-col :span="24" v-for="(item, index) in bases" :key="item.ID">
              <ProduceBase :mode="item.mode" :dataSource="item">
                <a-space slot="update" slot-scope="{ validate }">
                  <a-button
                    @click="cancelBase(item, index)"
                    type="primary"
                    ghost
                    >取消</a-button
                  >
                  <a-button @click="saveBase(validate, index)" type="primary"
                    >保存</a-button
                  >
                </a-space>
                <a-space slot="display">
                  <a-tooltip>
                    <template slot="title">修改</template>
                    <a-icon type="form" @click="updateState(item, 'update')" />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">删除</template>
                    <a-icon type="delete" @click="delBase(index)" />
                  </a-tooltip>
                </a-space>
              </ProduceBase>
            </a-col>
          </a-row>
        </a-collapse-panel> -->
        <a-collapse-panel
          style="border: none"
          key="3"
          :showArrow="false"
          :disabled="true"
        >
          <Header slot="header">
            <div class="flex items-center">
              <span class="mr-10">所获荣誉</span>
              <CompanyStatus :AuditStatus="origin.AuditStatus" />
            </div>
          </Header>
          <Extra @click.native="addHonor" v-if="isAddHonor" slot="extra" />
          <a-row>
            <a-col :span="24" v-for="(item, index) in honors" :key="item.ID">
              <!-- display 和 update -->
              <Honors :index="index + 1" :mode="item.mode" :dataSource="item">
                <a-space slot="update" slot-scope="{ validate }">
                  <a-button
                    @click="cancelHonor(item, index)"
                    type="primary"
                    ghost
                    >取消</a-button
                  >
                  <a-button @click="saveHonor(validate, index)" type="primary"
                    >保存</a-button
                  >
                </a-space>
                <a-space slot="display">
                  <a-tooltip>
                    <template slot="title">修改</template>
                    <a-icon type="form" @click="updateState(item, 'update')" />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">删除</template>
                    <a-icon type="delete" @click="delHonor(index)" />
                  </a-tooltip>
                </a-space>
              </Honors>
            </a-col>
          </a-row>
        </a-collapse-panel>
        <a-collapse-panel
          style="border: none"
          key="4"
          :showArrow="false"
          :disabled="true"
        >
          <Header slot="header">所获专利</Header>
          <Extra @click.native="addPatent" v-if="isAddPatent" slot="extra" />
          <a-row>
            <a-col :span="24" v-for="(item, index) in patents" :key="item.ID">
              <!-- display 和 update -->
              <Patents :index="index + 1" :mode="item.mode" :dataSource="item">
                <a-space slot="update" slot-scope="{ validate }">
                  <a-button
                    @click="cancelPatent(item, index)"
                    type="primary"
                    ghost
                    >取消</a-button
                  >
                  <a-button @click="savePatent(validate, index)" type="primary"
                    >保存</a-button
                  >
                </a-space>
                <a-space slot="display">
                  <a-tooltip>
                    <template slot="title">修改</template>
                    <a-icon type="form" @click="updateState(item, 'update')" />
                  </a-tooltip>
                  <a-tooltip>
                    <template slot="title">删除</template>
                    <a-icon type="delete" @click="delPatent(index)" />
                  </a-tooltip>
                </a-space>
              </Patents>
            </a-col>
          </a-row>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <div class="text-right px-20">
      <a-button
        v-if="
          !isManager &&
          origin.AuditStatus === 0 &&
          honors.length &&
          patents.length
        "
        :disabled="origin.AuditStatus !== 0"
        type="primary"
        @click="submitInfo"
        >提交</a-button
      >
      <a-button
        v-if="isManager && origin.AuditStatus === 1"
        type="primary"
        @click="modal = true"
        >审核</a-button
      >
    </div>

    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluateRecoginze"
    >
      <a-form-model :model="evaluate">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import MainProduct from "./components/MainProduct.vue";
import ProduceBase from "./components/ProduceBase.vue";
import Honors from "./components/Honors.vue";
import Patents from "./components/Patents.vue";
import Header from "./components/header.vue";
import Extra from "./components/extra.vue";
import CompanyStatus from "@/components1/status/companyStatus.vue";
import { saveCompanyRecoginze, getCompanyRecoginze } from "@/api";
import {
  getProCompany,
  evaluateProState,
  submitInfo,
  updateProCompany,
} from "@/api/company.js";
import moment from "moment";
export default {
  components: {
    MainProduct,
    ProduceBase,
    Honors,
    Patents,
    Header,
    Extra,
    CompanyStatus,
  },
  data() {
    return {
      products: [],
      bases: [],
      honors: [],
      patents: [],
      origin: {},
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      modal: false,
    };
  },
  computed: {
    disabled() {
      return (
        (this.isManager && this.origin.AuditStatus !== 1) ||
        (!this.isManager && this.origin.AuditStatus === 1)
      );
    },
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
    activeKey() {
      let active = [];
      // if (this.products.length) active.push("1");
      // if (this.bases.length) active.push("2");
      if (this.honors.length) active.push("3");
      if (this.patents.length) active.push("4");
      return active;
    },
    isAddProduct() {
      const isUpdate = !this.products.some(
        (product) => product.mode === "update"
      );
      return isUpdate;
    },
    isAddBase() {
      const isUpdate = !this.bases.some((base) => base.mode === "update");
      return isUpdate;
    },
    isAddHonor() {
      const isUpdate = !this.honors.some((honor) => honor.mode === "update");
      return isUpdate;
    },
    isAddPatent() {
      const isUpdate = !this.patents.some((patent) => patent.mode === "update");
      return isUpdate;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getCompanyRecoginze();
  },
  methods: {
    // 获取后台企业情况
    getProCompany(prop) {
      getProCompany(this.id).then(({ data }) => {
        const state = data.Detail ? data.Detail.State : {} || {};
        this.origin = state || {};
        const honors = state.Honors || [],
          patents = state.Patents || [];

        // if (prop === "Products" || !prop) {
        //   this.products = products.map((product, i) => {
        //     const temp = { ...product };
        //     temp.Photos = temp.Photos[0];
        //     temp.ID = i + 1;
        //     return temp;
        //   });
        // }
        // if (prop === "Bases" || !prop) {
        //   this.bases = bases.map((product, i) => {
        //     const temp = { ...product };
        //     temp.Photos = temp.Photos[0];
        //     temp.ID = i + 1;
        //     return temp;
        //   });
        // }
        if (prop === "Honors" || !prop) {
          this.honors = honors.map((honor, i) => {
            const temp = { ...honor };
            temp.Time = moment(temp.Time);
            temp.ID = i + 1;
            return temp;
          });
        }
        if (prop === "Patents" || !prop) {
          this.patents = patents.map((patent, i) => {
            const temp = { ...patent };
            temp.Time = moment(temp.Time);
            temp.ID = i + 1;
            return temp;
          });
        }
      });
    },
    // 获取企业情况
    getCompanyRecoginze(prop) {
      if (this.id && this.isManager) {
        this.getProCompany(prop);
      } else {
        getCompanyRecoginze().then(({ data }) => {
          const state = data.Detail ? data.Detail.State : {} || {};
          this.origin = state || {};
          const products = state.Products || [],
            bases = state.Bases || [],
            honors = state.Honors || [],
            patents = state.Patents || [];

          if (prop === "Products" || !prop) {
            this.products = products.map((product, i) => {
              const temp = { ...product };
              temp.Photos = temp.Photos[0];
              temp.ID = i + 1;
              return temp;
            });
          }
          if (prop === "Bases" || !prop) {
            this.bases = bases.map((product, i) => {
              const temp = { ...product };
              temp.Photos = temp.Photos[0];
              temp.ID = i + 1;
              return temp;
            });
          }
          if (prop === "Honors" || !prop) {
            this.honors = honors.map((honor, i) => {
              const temp = { ...honor };
              temp.Time = moment(temp.Time);
              temp.ID = i + 1;
              return temp;
            });
          }
          if (prop === "Patents" || !prop) {
            this.patents = patents.map((patent, i) => {
              const temp = { ...patent };
              temp.Time = moment(temp.Time);
              temp.ID = i + 1;
              return temp;
            });
          }
        });
      }
    },
    // 更新后台企业情况
    updateProCompany(prop, temp) {
      return updateProCompany(this.id, { state: temp }).then(() => {
        this.getProCompany(prop);
      });
    },
    // 更新企业情况
    saveCompanyRecoginze(prop, data) {
      const temp = { ...this.origin };
      temp[prop] = data;
      if (this.id && this.isManager) {
        return this.updateProCompany(prop, temp);
      } else {
        return saveCompanyRecoginze({ state: temp }).then(() => {
          this.getCompanyRecoginze(prop);
        });
      }
    },
    // 添加主营产品
    addProduct() {
      this.products.push({ mode: "update" });
    },
    // 删除主营产品
    delProduct(i) {
      this.products.splice(i, 1);
      const products = this.products.map((product) => {
        const temp = { ...product };
        temp.Photos = [temp.Photos];
        return temp;
      });
      this.saveCompanyRecoginze("Products", products);
    },
    // 保存主营产品
    saveProduct(validate, i) {
      validate().then((res) => {
        this.products[i] = res;
        const products = this.products.map((product) => {
          const temp = { ...product };
          temp.Photos = [temp.Photos];
          return temp;
        });
        this.saveCompanyRecoginze("Products", products);
      });
    },
    // 取消保存主营产品
    cancelProduct(data, i) {
      if (data.ID) {
        this.updateState(data, "display");
      } else {
        this.delProduct(i);
      }
    },
    // 添加生产基地
    addBase() {
      this.bases.push({ mode: "update" });
    },
    // 删除生产基地
    delBase(i) {
      this.bases.splice(i, 1);
      const bases = this.bases.map((base) => {
        const temp = { ...base };
        temp.Photos = [temp.Photos];
        return temp;
      });
      this.saveCompanyRecoginze("Bases", bases);
    },
    // 保存生产基地
    saveBase(validate, i) {
      validate().then((res) => {
        this.bases[i] = res;
        const bases = this.bases.map((base) => {
          const temp = { ...base };
          temp.Photos = [temp.Photos];
          return temp;
        });
        this.saveCompanyRecoginze("Bases", bases);
      });
    },
    // 取消保存生产基地
    cancelBase(data, i) {
      if (data.ID) {
        this.updateState(data, "display");
      } else {
        this.delBase(i);
      }
    },
    // 添加荣誉
    addHonor() {
      this.honors.push({ mode: "update" });
    },
    // 删除荣誉
    delHonor(i) {
      this.honors.splice(i, 1);
      this.saveCompanyRecoginze("Honors", this.honors);
    },
    // 保存荣誉
    saveHonor(validate, i) {
      validate().then((res) => {
        this.honors[i] = res;
        this.saveCompanyRecoginze("Honors", this.honors);
      });
    },
    // 取消保存荣誉
    cancelHonor(data, i) {
      if (data.ID) {
        this.updateState(data, "display");
      } else {
        this.delHonor(i);
      }
    },
    // 添加专利
    addPatent() {
      this.patents.push({ mode: "update" });
    },
    // 删除专利
    delPatent(i) {
      this.patents.splice(i, 1);
      this.saveCompanyRecoginze("Patents", this.patents);
    },
    // 保存专利
    savePatent(validate, i) {
      validate()
        .then((res) => {
          this.patents[i] = res;
          this.saveCompanyRecoginze("Patents", this.patents);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 取消保存专利
    cancelPatent(data, i) {
      if (data.ID) {
        this.updateState(data, "display");
      } else {
        this.delPatent(i);
      }
    },
    // 修改状态
    updateState(data, state) {
      this.$set(data, "mode", state);
    },
    // 提交审核
    submitInfo() {
      submitInfo().then(() => {
        this.$message.success("提交成功");
        this.getCompanyRecoginze();
      });
    },
    // 审核
    evaluateRecoginze() {
      evaluateProState(this.id, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.modal = false;
        this.getProCompany(this.id);
      });
    },
  },
};
</script>

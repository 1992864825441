<template>
  <div class="hover:bg-f9 px-20 py-10" :class="{ 'bg-f9': mode === 'update' }">
    <!-- display -->
    <div v-if="mode === 'display'">
      <div class="flex items-center">
        <div class="flex-grow flex">
          <span>{{ index }}、</span>
          <span class="mr-1">{{ model.Time | formatterTime1 }}</span>
          <span>{{ model.LevelName }}</span>
          <span class="mx-1">---</span>
          <span>{{ model.ClassName }}</span>
          <span class="mx-1">---</span>
          <span>
            <span class="mr-1">{{ model.Name }}</span>
            <span>({{ model.Authority }})</span>
          </span>
        </div>

        <div class="flex-shrink-0">
          <slot name="display"></slot>
        </div>
      </div>
    </div>
    <!-- update -->
    <div v-else-if="mode === 'update'">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 15 }"
        labelAlign="left"
        :colon="false"
      >
        <gf-form-item
          prop="Level"
          label="荣誉级别"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-select v-model="model.Level" flag="HonorLevel"></gf-select>
        </gf-form-item>
        <gf-form-item
          prop="Class"
          label="荣誉类别"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-select v-model="model.Class" flag="HonorClass"></gf-select>
        </gf-form-item>
        <gf-form-item
          prop="Name"
          label="荣誉名称"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-re-input placeholder="请输入" v-model="model.Name"></gf-re-input>
        </gf-form-item>
        <gf-form-item
          prop="Authority"
          label="授奖部门"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-re-input
            placeholder="请输入"
            v-model="model.Authority"
          ></gf-re-input>
        </gf-form-item>
        <gf-form-item
          prop="Time"
          label="授奖时间"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <a-date-picker
            placeholder="请选择年/月/日"
            valueFormat="x"
            v-model="model.Time"
          ></a-date-picker>
        </gf-form-item>
      </a-form-model>
      <div class="text-right">
        <slot name="update" v-bind="{ validate }"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    mode: {
      type: String,
      default: "display",
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
  },
  computed: {
    logo() {
      const id = this.model.Photos ? this.model.Photos.ID : "";
      return upload() + "/" + id;
    },
    options() {
      return this.$store.state.options;
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    const temp = { ...this.dataSource };
    if (temp.Class) {
      temp.ClassName = this.options.HonorClass.find(
        (item) => item.ID === temp.Class
      ).Name;
    }
    if (temp.Level) {
      temp.LevelName = this.options.HonorLevel.find(
        (item) => item.ID === temp.Level
      ).Name;
    }
    this.model = temp;
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            const temp = { ...this.model };
            temp.ClassName = this.options.HonorClass.find(
              (item) => item.ID === temp.Class
            ).Name;
            temp.LevelName = this.options.HonorLevel.find(
              (item) => item.ID === temp.Level
            ).Name;
            temp.Time = new Date(+temp.Time).toISOString();
            this.model = temp;
            resolve(temp);
          } else {
            reject();
          }
        });
      });
    },
  },
  watch: {
    dataSource(val) {
      if (val.Class) {
        val.ClassName = this.options.HonorClass.find(
          (item) => item.ID === val.Class
        ).Name;
      }
      if (val.Level) {
        val.LevelName = this.options.HonorLevel.find(
          (item) => item.ID === val.Level
        ).Name;
      }
      this.model = val;
    },
  },
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hover:bg-f9 px-20 py-10",class:{ 'bg-f9': _vm.mode === 'update' }},[(_vm.mode === 'display')?_c('div',[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex-grow flex"},[_c('span',[_vm._v(_vm._s(_vm.index)+"、")]),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm._f("formatterTime1")(_vm.model.Time)))]),_c('span',[_vm._v(_vm._s(_vm.model.ClassName))]),_c('span',{staticClass:"mx-1"},[_vm._v("---")]),_c('span',[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.model.Name))]),_c('span',[_vm._v("("+_vm._s(_vm.model.Number)+")")])])]),_c('div',{staticClass:"flex-shrink-0"},[_vm._t("display")],2)])]):(_vm.mode === 'update')?_c('div',[_c('a-form-model',{ref:"container",attrs:{"model":_vm.model,"labelCol":{ span: 8 },"wrapperCol":{ span: 15 },"labelAlign":"left","colon":false}},[_c('gf-form-item',{attrs:{"prop":"Class","label":"专利类别","rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('gf-select',{attrs:{"flag":"PatentClass"},model:{value:(_vm.model.Class),callback:function ($$v) {_vm.$set(_vm.model, "Class", $$v)},expression:"model.Class"}})],1),_c('gf-form-item',{attrs:{"prop":"Name","label":"专利名称","rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('gf-re-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.model.Name),callback:function ($$v) {_vm.$set(_vm.model, "Name", $$v)},expression:"model.Name"}})],1),_c('gf-form-item',{attrs:{"prop":"Number","label":"专利号","rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('gf-re-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.model.Number),callback:function ($$v) {_vm.$set(_vm.model, "Number", $$v)},expression:"model.Number"}})],1),_c('gf-form-item',{attrs:{"prop":"Time","label":"申请时间","rules":{
          required: true,
          message: '请输入',
          trigger: ['change', 'blur'],
        }}},[_c('a-date-picker',{attrs:{"placeholder":"请选择年/月/日","valueFormat":"x"},model:{value:(_vm.model.Time),callback:function ($$v) {_vm.$set(_vm.model, "Time", $$v)},expression:"model.Time"}})],1)],1),_c('div',{staticClass:"text-right"},[_vm._t("update",null,null,{ validate: _vm.validate })],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
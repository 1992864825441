<template>
  <div class="hover:bg-f9 px-20 py-10" :class="{ 'bg-f9': mode === 'update' }">
    <!-- display -->
    <div v-if="mode === 'display'">
      <div class="flex">
        <div
          style="width: 214px; height: 160px"
          class="mr-20 border border-eee"
        >
          <img
            class="w-full h-full object-contain"
            v-if="logo"
            :src="logo"
            alt=""
          />
        </div>
        <div style="width: calc(100% - 234px)">
          <div class="flex">
            <div class="flex-grow font-bold">
              {{ model.Name }}
            </div>
            <div class="flex-shrink-0">
              <slot name="display"></slot>
            </div>
          </div>
          <div class="mt-10 text-sm">
            <div class="text-999 mb-10">产品详情：</div>
            <div>
              {{ model.Desc }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- update -->
    <div v-else-if="mode === 'update'">
      <a-form-model
        ref="container"
        :model="model"
        :labelCol="{ span: 8 }"
        :wrapperCol="{ span: 15 }"
        labelAlign="left"
        :colon="false"
      >
        <gf-form-item
          prop="Name"
          label="产品名称"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-re-input placeholder="请选择" v-model="model.Name"></gf-re-input>
        </gf-form-item>
        <gf-form-item
          prop="Desc"
          label="产品详情"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <gf-re-textarea
            :rows="5"
            :max="300"
            v-model="model.Desc"
          ></gf-re-textarea>
        </gf-form-item>
        <gf-form-item
          prop="Photos"
          label="产品海报"
          subLabel="支持JPG、PNG、JPEG等格式文件需小于5M，建议尺寸800*600"
          :wrapperCol="{ span: 7 }"
          :rules="{
            required: true,
            message: '请输入',
            trigger: ['change', 'blur'],
          }"
        >
          <picture-upload
            :scale="{ w: 330, h: 248 }"
            v-model="model.Photos"
            :width="330"
            :height="248"
            accept=".png,.jpg,.jpeg"
            :size="5"
            field="6"
          ></picture-upload>
        </gf-form-item>
      </a-form-model>
      <div class="text-right">
        <slot name="update" v-bind="{ validate }"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from "@/api";
export default {
  props: {
    mode: {
      type: String,
      default: "display",
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    logo() {
      const id = this.model.Photos ? this.model.Photos.ID : "";
      return upload() + "/" + id;
    },
  },
  data() {
    return {
      model: {},
    };
  },
  created() {
    this.model = this.dataSource;
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            const temp = { ...this.model };
            resolve(temp);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>